<template>
    <Panel>
        <template #header>
            <div style="display: flex; flex-direction: column; gap: 1rem;">
                <span style="font-weight: bold;">
                    SWOT Analyses
                </span>
                <Button type="button" icon="pi pi-plus" label="Add SWOT item" class="p-button-outlined"
                    @click="openAddSWOTItem()" />
            </div>
        </template>

        <!-- {{companySWOTAnalysesType}} -->

        <div style="
            display: grid; 
            grid-template-rows: 50% 50% ;
            grid-template-columns: 50% 50%;
            gap: 1rem;
            grid-auto-flow: column;
        ">
            <!-- :value="companySWOTAnalysesType[0]"
                :loading="loading"
                responsiveLayout="scroll" -->

            <div v-for="(csaT, index) of companySWOTAnalysesType"  :key="csaT.companyAnalysesTypeId">
                <Panel style="margin-bottom: 5rem;">
                    <!-- panel {{index +1}} -->
                    <!-- <div v-if="csaT.companyAnalysesItems.length > 0 "> -->
                        <DataTable
                            :value="csaT.companyAnalysesItems"
                            :loading="loading"
                        >
                            <!-- :rows="10" 
                            :paginator="true" -->
                            <!-- Description  -->
                            <Column
                                :header="getSWOTType(index)"
                                style="width: 25%;"
                                
                            >
                            <!-- :field="'companyAnalysesItem'" -->
                                <template #body="slotProps">
                                    <div @click="openEditSWOTItem(slotProps.data)">
                                        {{slotProps.data.companyAnalysesItem}}
                                    </div>
                                </template>
                            </Column>

                            <Column
                                style="width: 5%;"
                            >
                                <template #header>
                                    <div>
                                        <Button 
                                            type="button" 
                                            icon="pi pi-plus" 
                                            label="Add " 
                                            class="p-button-outlined"
                                            @click="openAddSWOTItemWithSWOTTypeId(index)"
                                        />
                                        
                                    </div>
                                </template>
                                <template #body="slotProps">
                                    <div>
                                        <font-awesome-icon icon="fa-regular fa-globe" v-if="getSWOTTypeIcon(slotProps.data.topicId) === 'Global'" />
                                        <font-awesome-icon icon="fa-regular fa-tag" v-else />
                                        <!-- {{ getSWOTTypeIcon(slotProps.data.topicId)  }} -->
                                    </div>
                                </template>
                            </Column>

                            <Column
                                :header="'Updated'"
                                style="width: 5%;"
                            >
                                <template #body="slotProps">
                                    <div>
                                        {{ formatDate( slotProps.data.companyAnalysesItemUpdate )}}
                                    </div>
                                </template>
                            </Column>

                            <Column
                                style="width: 5%;"
                            >
                                <template #body="slotProps">
                                    <div
                                        style="
                                            display: flex;
                                            justify-content: space-between;
                                        "
                                    >
                                        <div style="display: flex;">
                                            <!-- up -->
                                            <Button 
                                                :disabled="slotProps.data.companyAnalysesItemOrder == 0"
                                                icon="pi pi-arrow-up"
                                                class="p-button-rounded p-button-info mx-2"
                                                @click="changeSWOTAnalysesItemOrder( slotProps.data , true)"
                                            />
                                            <!-- down -->
                                            <Button
                                                :disabled="slotProps.data.companyAnalysesItemOrder == csaT.companyAnalysesItems.length -1"
                                                icon="pi pi-arrow-down"
                                                class="p-button-rounded p-button-danger"
                                                @click="changeSWOTAnalysesItemOrder( slotProps.data , false)"
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                icon="pi pi-trash"
                                                class="p-button-rounded p-button-danger"
                                                @click="openDeleteSWOTItemDialog(slotProps.data)"
                                            />
                                        </div>
                                    </div>
                                    
                                </template>
                            </Column>
                        </DataTable>
                    <!-- </div> -->
                    <!-- <div v-if="csaT">
                        {{csaT.companyAnalysesItems}}
                    </div> -->
                </Panel>
            </div>
        </div>
    </Panel>

    <!-- add SWOT ITEM DIALOG -->
    <Dialog @hide="closeDialog" v-model:visible="addSWOTItemBool" :style="{ width: '80%' }" header="Add SWOT Item " :modal="true">
        <!-- Body -->
        <div style="
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin: 0 2.5% 0 2.5%;
        ">
            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label 
                        style="
                            width: 100%;
                        "
                    >SWOT Analyses Type</label>
                </div>
                <div
                    style="
                        width: 75%;
                    "
                >
                    <Dropdown 
                        v-model="createSWOTItemForm.companyAnalysesTypeId"
                        :options="companySWOTAnalysesType"
                        optionLabel="companyAnalysesTypeName"
                        optionValue="companyAnalysesTypeId"
                        class="p-button-outlined me-4"
                        style="
                            width: 100%;
                            margin: none;
                            /* background-color: red; */
                        "
                        placeholder="SWOT Analyses Type"
                    />
                </div>
            </div>

            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label style="width: 100%;" for="CompanyAnalysesItem">Company Analyses </label>

                </div>
                <div
                    style="
                        width: 75%;
                    "
                >
                    <InputText 
                        type="text" 
                        placeholder="Company Analyses " 
                        style="width: 100%"
                        autocomplete="off"
                        v-model="createSWOTItemForm.companyAnalysesItem" 
                    />
                </div>
            </div>

            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label style="width: 100%;" for="CompanyAnalysesItemGlobal"> Global </label>
                </div>
                <div
                    style="
                        width: 75%;
                    "
                >
                    <!-- {{ createSWOTItemForm.global }} -->
                    <Checkbox 
                        v-model="createSWOTItemForm.global"
                        :binary="true"
                    />
                </div>
            </div>

            <div 
                v-if="!createSWOTItemForm.global"
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                "
            >
                <!-- show topic Dropdown -->
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label style="width: 100%;" for="TopicRelated"> Topic Related </label>
                </div>

                <div
                    style="
                        width: 75%;
                    "
                >
                    <Dropdown 
                        v-model="topicId"
                        :options="topics"
                        optionLabel="name"
                        optionValue="topicId"
                        class="p-button-outlined me-4"
                        filter 
                        style="
                            width: 100%;
                            margin: none;
                            /* background-color: red; */
                        "
                        placeholder="Topic Related"
                    />

                    <div v-if="topicRequiredMessage">
                        <label for="ErrorTopicRequiredMessage" style="color: red;">{{ topicRequiredMessage  }}</label> 
                    </div>
                </div>
            </div>
        </div>
        
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" @click="closeDialog" />
            <Button 
                label="Save" 
                icon="pi pi-check" 
                class=" p-button-success mx-2 "
                :loading="createSWOTItemDialogIsLoading" 
                :disabled="this.v$.createSWOTItemForm.$invalid || createSWOTItemDialogIsLoading"
                @click="createSWOTItemDialog" 
            />
            
        </template>
    </Dialog>

    <!-- add SWOT ITEM DIALOG By TypeId-->
    <Dialog @hide="closeDialog" v-model:visible="addSWOTItemBoolByType" :style="{ width: '80%' }" header="Add SWOT Item " :modal="true">
        <!-- Body -->
        <div style="
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin: 0 2.5% 0 2.5%;
        ">
            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label 
                        style="
                            width: 100%;
                        "
                    >SWOT Analyses Type</label>
                </div>
                <div
                    style="
                        width: 75%;
                    "
                >
                    <Dropdown 
                        v-model="createSWOTItemForm.companyAnalysesTypeId"
                        :options="companySWOTAnalysesType"
                        optionLabel="companyAnalysesTypeName"
                        optionValue="companyAnalysesTypeId"
                        class="p-button-outlined me-4"
                        style="
                            width: 100%;
                            margin: none;
                            /* background-color: red; */
                        "
                        placeholder="SWOT Analyses Type"
                        disabled
                    />
                </div>
            </div>

            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label style="width: 100%;" for="CompanyAnalysesItem">Company Analyses </label>

                </div>
                <div
                    style="
                        width: 75%;
                    "
                >
                    <InputText 
                        type="text" 
                        placeholder="Company Analyses " 
                        style="width: 100%"
                        autocomplete="off"
                        v-model="createSWOTItemForm.companyAnalysesItem" 
                    />
                </div>
            </div>

            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label style="width: 100%;" for="CompanyAnalysesItemGlobal"> Global </label>
                </div>
                <div
                    style="
                        width: 75%;
                    "
                >
                    <!-- {{ createSWOTItemForm.global }} -->
                    <Checkbox 
                        v-model="createSWOTItemForm.global"
                        :binary="true"
                    />
                </div>
            </div>

            <div 
                v-if="!createSWOTItemForm.global"
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                "
            >
                <!-- show topic Dropdown -->
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label style="width: 100%;" for="TopicRelated"> Topic Related </label>
                </div>

                <div
                    style="
                        width: 75%;
                    "
                >
                    <Dropdown 
                        v-model="topicId"
                        :options="topics"
                        optionLabel="name"
                        optionValue="topicId"
                        class="p-button-outlined me-4"
                        filter 
                        style="
                            width: 100%;
                            margin: none;
                            /* background-color: red; */
                        "
                        placeholder="Topic Related"
                    />

                    <div v-if="topicRequiredMessage">
                        <label for="ErrorTopicRequiredMessage" style="color: red;">{{ topicRequiredMessage  }}</label> 
                    </div>
                </div>
            </div>
        </div>
        
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" @click="closeDialog" />
            <Button 
                label="Save" 
                icon="pi pi-check" 
                class=" p-button-success mx-2 "
                :loading="createSWOTItemDialogIsLoading" 
                :disabled="this.v$.createSWOTItemForm.$invalid || createSWOTItemDialogIsLoading"
                @click="createSWOTItemDialog" 
            />
            
        </template>
    </Dialog>

    <!-- Edit Swot Item Dialog -->
    <Dialog @hide="closeDialog" v-model:visible="editSWOTItemDialog" :style="{ width: '80%' }" header="Edit SWOT Item " :modal="true">
        <!-- Body -->
        <div 
            style="
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin: 0 2.5% 0 2.5%;
            "
        >
            <!-- companyAnalysesTypeId -->
            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label 
                        style="
                            width: 100%;
                        "
                    >SWOT Analyses Type</label>
                </div>
                <div
                    style="
                        width: 75%;
                    "
                >
                    <Dropdown 
                        v-model="editSWOTItemForm.companyAnalysesTypeId"
                        :options="companySWOTAnalysesType"
                        optionLabel="companyAnalysesTypeName"
                        optionValue="companyAnalysesTypeId"
                        class="p-button-outlined me-4"
                        style="
                            width: 100%;
                            margin: none;
                            /* background-color: red; */
                        "
                        placeholder="SWOT Analyses Type"
                        disabled
                    />
                </div>
            </div>

            <!-- companyAnalysesItem -->
            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label style="width: 100%;" for="CompanyAnalysesItem">Company Analyses </label>

                </div>
                <div
                    style="
                        width: 75%;
                    "
                >
                    <InputText 
                        type="text" 
                        placeholder="Company Analyses " 
                        style="width: 100%"
                        autocomplete="off"
                        v-model="editSWOTItemForm.companyAnalysesItem" 
                    />
                </div>
            </div>

            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        width: 25%;
                    "
                >
                    <label style="width: 100%;" for="CompanyAnalysesItemGlobal"> Global </label>
                </div>
                <div
                    style="
                        width: 75%;
                    "
                >
                    <!-- {{ createSWOTItemForm.global }} -->
                    <Checkbox 
                        v-model="editSWOTItemForm.global"
                        :binary="true"
                    />
                </div>
            </div>

            <div v-if="!editSWOTItemForm.global"
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                "
            >
                <!-- show topic Dropdown -->
                <!-- {{ topics  }} -->

                <div
                    style="
                        width: 25%;
                    "
                >
                    <label style="width: 100%;" for="TopicRelated"> Topic Related </label>
                </div>

                <div
                    style="
                        width: 75%;
                    "
                >
                    <Dropdown 
                        v-model="topicId"
                        :options="topics"
                        optionLabel="name"
                        optionValue="topicId"
                        class="p-button-outlined me-4"
                        filter 
                        style="
                            width: 100%;
                            margin: none;
                            /* background-color: red; */
                        "
                        placeholder="Topic Related"
                    />

                    <div v-if="topicRequiredMessage">
                        <label for="ErrorTopicRequiredMessage" style="color: red;">{{ topicRequiredMessage  }}</label> 
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Cancel" icon="pi pi-times" @click="closeDialog" />
            <Button 
                label="Save" 
                icon="pi pi-check" 
                class=" p-button-success mx-2 "
                :loading="editSWOTItemIsLoading"
                :disabled="this.v$.editSWOTItemForm.$invalid || editSWOTItemIsLoading"
                @click="editSWOTITem"
            />
            <!-- :loading="createSWOTItemDialogIsLoading" 
                :disabled="this.v$.createSWOTItemForm.$invalid || createSWOTItemDialogIsLoading"
                @click="createSWOTItemDialog"  -->
            
        </template>
    </Dialog>

    <!-- Delete SWOT ITEM DIALOG -->
    <Dialog @hide="closeDialog" v-model:visible="deleteSWOTItemDialog" :style="{ width: '450px' }" header="Delete SWOT Item" :modal="true">
        <div class="confirmation-content d-flex flex-row">
        <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
        <span
            >Are you sure you want to delete
            <span class="fw-bold">{{ deleteSWOTItemform.companyAnalysesItem }}</span>
        </span>
        </div>
        <template #footer>
        <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="closeDialog()"
        />
        <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            :loading="deleteSWOTItemIsLoading"
            :disabled="deleteSWOTItemIsLoading"
            @click="deleteSWOTItem(this.deleteSWOTItemform.companyAnalysesItemId)"
        />
        </template>
    </Dialog>


</template>
<script>
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox'

import formatToGlobalDate from '@/shared/helpers/date.helper';
import { changeOrderCompanyAnalysesItem } from '../services/company.service'; 


import useVuelidate from "@vuelidate/core";
import { required } from '@vuelidate/validators';

export default {
    name: 'SWOTList',
    components: {
        // Card,
        Button,
        Panel,
        Dialog,
        Dropdown,
        InputText,
        DataTable,
        Column,
        Checkbox
    },
    props: ['companyId'],

    setup: () => ({ v$: useVuelidate() }),

    data() {
        return {
            loading: true,
            addSWOTItemBool: false,
            addSWOTItemBoolByType:false,
            createSWOTItemDialogIsLoading: false,
            createSWOTItemForm: {
                companyAnalysesItem: null,
                companyAnalysesTypeId: null,
                companyId: this.companyId,
                global: true,
            },
            
            topicId: null,

            topicRequiredMessage: null,
            deleteSWOTItemDialog: false,
            deleteSWOTItemIsLoading: false,
            deleteSWOTItemform: {
                companyAnalysesItem: null,
                companyAnalysesItemId: null,
            },

            // edit
            editSWOTItemDialog: false,
            editSWOTItemIsLoading: false,
            editSWOTItemForm: {
                companyAnalysesItem: null,
                companyAnalysesTypeId: null,
                companyAnalysesItemId:null,
                companyId: this.companyId,
                global: true,
            },

            changeOrderLoading: false, 
        }
    },
    validations() {
        return {
            createSWOTItemForm: {
                companyAnalysesItem: { required },
                companyAnalysesTypeId: { required },
                companyId: { required },
            },

            editSWOTItemForm:{
                companyAnalysesItem: { required },
                companyAnalysesTypeId: { required },
                companyId: { required },
            }
        }
    },
    async mounted() {
        await this.getCompanyAnalysesType()
        await this.getTopics();
        // this.createSWOTItemForm
    },
    computed: {
        companySWOTAnalysesType() {
            return this.$store.getters.obtainSwotCompanyAnalysesType
        },
        topics() {
            const sortedAlphaTopics = this.$store.getters.obtainTopics;
            return sortedAlphaTopics.sort((a,b) => a.name > b.name ? 1 : -1 ) ;
        },
    },
    methods: {
        async getCompanyAnalysesType() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainSwotCompanyAnalysesType', this.companyId)
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },

        async getTopics() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainTopics');
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },

        openAddSWOTItem() {
            this.addSWOTItemBool = true;
        },

        openAddSWOTItemWithSWOTTypeId(typeIndex) {
            this.addSWOTItemBoolByType = true;
            const newCompanySWOTAnalysesType = this.companySWOTAnalysesType[typeIndex];
            this.createSWOTItemForm.companyAnalysesTypeId = newCompanySWOTAnalysesType.companyAnalysesTypeId
        },

        closeDialog() {
            this.addSWOTItemBool = false;
            this.addSWOTItemBoolByType = false;

            this.createSWOTItemForm = {
                companyAnalysesItem: null,
                companyAnalysesTypeId: null,
                companyId: this.companyId,
                global: true,
            },
            this.topicId = null,

            this.topicRequiredMessage = null;

            this.editSWOTItemForm ={
                companyAnalysesItem: null,
                companyAnalysesTypeId: null,
                companyId: this.companyId,
                global: true,
            }

            this.editSWOTItemDialog = false;

            this.deleteSWOTItemDialog = false;

            this.deleteSWOTItemform =  {
                companyAnalysesItem: null,
                companyAnalysesItemId: null,
            }
        },

        async createSWOTItemDialog() {
            try {
                this.createSWOTItemDialogIsLoading = true;

                if(this.createSWOTItemForm.global){
                    const form = {
                        companyAnalysesItem: this.createSWOTItemForm.companyAnalysesItem,
                        companyAnalysesTypeId:  this.createSWOTItemForm.companyAnalysesTypeId,
                        companyId: this.companyId,
                    }
                    await this.$store.dispatch('addCompanyAnalysesItems', form);
                    this.closeDialog();
                }
                else{
                    const form = {
                        companyAnalysesItem: this.createSWOTItemForm.companyAnalysesItem,
                        companyAnalysesTypeId:  this.createSWOTItemForm.companyAnalysesTypeId,
                        companyId: this.companyId,
                        topicId: this.topicId,
                    }

                    if(!form.topicId){
                        this.topicRequiredMessage = 'Topic is Required'
                    }
                    else{
                        await this.$store.dispatch('addCompanyAnalysesItems', form);
                        this.closeDialog();
                    }
                }
                this.createSWOTItemDialogIsLoading = false;
                
            } catch (error) {
                this.createSWOTItemDialogIsLoading = false;
            }
        },

        setSWOTItemForm() {
            this.createSWOTItemForm.companyAnalysesItem
            this.createSWOTItemForm.companyAnalysesTypeId
            this.createSWOTItemForm.companyId
            this.createSWOTItemForm.topicId
        },

        getSWOTType(index){
            let SwotTypeName =  this.companySWOTAnalysesType.find(csat => csat.companyAnalysesOrder === index).companyAnalysesTypeName
            return SwotTypeName
        },

        getSWOTTypeIcon(topicId){
            return topicId ? 'NotGlobal' : 'Global'
        },

        formatDate(date) {
            if (!date) return '';
            return formatToGlobalDate(date);
        },

        async openDeleteSWOTItemDialog(swotItem){
            // await deleteSWOTItem(swotItem.companyAnalysesItemId)
            this.deleteSWOTItemform.companyAnalysesItem = swotItem.companyAnalysesItem;
            this.deleteSWOTItemform.companyAnalysesItemId = swotItem.companyAnalysesItemId;
            this.deleteSWOTItemDialog = true;
        },

        async deleteSWOTItem(swotItemId){
            try {
                this.deleteSWOTItemIsLoading = true;
                await this.$store.dispatch('deleteCompanyAnalysesItem', swotItemId);
                await this.getCompanyAnalysesType()
                this.deleteSWOTItemIsLoading = false;
                this.closeDialog();
            } catch (error) {
                this.deleteSWOTItemIsLoading = false;
            }
        },

        // EditSWOTItem

        openEditSWOTItem(swotItem){
            this.editSWOTItemForm.companyAnalysesTypeId = swotItem.companyAnalysesTypeId
            this.editSWOTItemForm.companyAnalysesItem = swotItem.companyAnalysesItem
            this.editSWOTItemForm.companyAnalysesItemId = swotItem.companyAnalysesItemId
            this.editSWOTItemForm.companyId = swotItem.companyId
            this.editSWOTItemForm.global= (swotItem.topicId) ? false : true 

            this.topicId = (swotItem.topicId) ? swotItem.topicId  : null  

            this.editSWOTItemDialog = true;
        },

        async editSWOTITem(){
            try {
                this.editSWOTItemIsLoading = true; 

                if(this.editSWOTItemForm.global){
                    const form = {
                        companyAnalysesItem: this.editSWOTItemForm.companyAnalysesItem,
                        companyAnalysesTypeId:  this.editSWOTItemForm.companyAnalysesTypeId,
                        companyId: this.companyId,
                    }
                    await this.$store.dispatch('editCompanyAnalysesItems', { form: form, companyAnalysesItemId: this.editSWOTItemForm.companyAnalysesItemId } );
                    this.closeDialog();
                }
                else{
                    const form = {
                        companyAnalysesItem: this.editSWOTItemForm.companyAnalysesItem,
                        companyAnalysesTypeId:  this.editSWOTItemForm.companyAnalysesTypeId,
                        companyId: this.companyId,
                        topicId: this.topicId,
                    }

                    if(!form.topicId){
                        this.topicRequiredMessage = 'Topic is Required'
                    }
                    else{
                        await this.$store.dispatch('editCompanyAnalysesItems', { form: form, companyAnalysesItemId: this.editSWOTItemForm.companyAnalysesItemId } );
                        this.closeDialog();
                    }
                }
                this.editSWOTItemIsLoading = false; 
            } catch (error) {
                this.createSWOTItemDialogIsLoading = false;
            }
        },

        // order 
        async changeSWOTAnalysesItemOrder( changeSWOTAnalysesItem , goUp){
            try {
                this.changeOrderLoading = true; 
                await changeOrderCompanyAnalysesItem(changeSWOTAnalysesItem.companyAnalysesItemId, goUp )
                await this.getCompanyAnalysesType()
                this.changeOrderLoading = false;
            } catch (error) {
                this.changeOrderLoading = false;
            }
        },

    },
    watch: {

    },
}
</script>

<style scoped></style>