<template>
    <div class="container">
        <template v-if="loading">
            <div class="row">
                <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-12 py-4">
                    <div class="p-fluid container-fluid">
                        <Button class="p-button-raised p-button-rounded my-3 p-button-danger" icon="pi pi-arrow-left"
                            @click="goBack" />
                        
                        
                        <div style="display:flex; justify-content: space-between; ">
                            <h3>Company </h3>
                            <!-- Usability Update 04-03-2024 Hide Selected Companies -->
                            <!-- <label class="mb-2 md:m-0 p-as-md-center"><strong>Selected</strong> Company <InputSwitch v-model="priority" style="margin-left: 0.7rem;"/> </label> -->
                        </div>

                        <!-- name  -->
                        <div class="row">
                            <div class="col-12">
                                <div class="field my-3">
                                <label for="title">Name</label>
                                <InputText 
                                    id="name" 
                                    v-model.trim="form.name" 
                                    @blur="v$.form.name.$touch"
                                    required="true" 
                                    autofocus 
                                    :class="{ 'p-invalid': !form.name }" />
                                <small class="p-error" v-if="!form.name">Name is required.</small>
                                </div>
                            </div>
                        </div>
                        <!-- Type,Country, numberOf Employees -->
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="my-3">
                                    <label for="CompanyType">Company Type</label>
                                    <Dropdown
                                        v-model="form.companyTypeId"
                                        :options="companyTypes"
                                        optionLabel="name"
                                        optionValue="companyTypeId"
                                        placeholder="Company types"
                                        :class="{ 'p-invalid': !form.companyTypeId }"
                                        :filter="true"
                                        :loading="loadingCompanyTypes"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="my-3">
                                    <label for="Country">Country</label>
                                    <Dropdown
                                        v-model="form.countryId"
                                        :options="countries"
                                        optionLabel="name"
                                        optionValue="countryId"
                                        placeholder="Country"
                                        :filter="true"
                                        :loading="loadingCountries"
                                    >
                                    </Dropdown>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="my-3">
                                    <label for="numberEmployees">Number of employees</label>
                                    <InputText 
                                        type="number"
                                        min="0"
                                        v-model="form.numberEmployees"
                                    />

                                </div>
                            </div>
                        </div>

                        <!-- address -->
                        <!-- <div class="row">
                            <div class="col-12">
                                <div class="field my-3">
                                    <label for="address">Address</label>
                                    <InputText
                                        id="address"
                                        v-model.trim="form.address"
                                        autofocus
                                    />
                                </div>
                            </div>
                        </div> -->

                        <!-- notes -->
                        <div class="row">
                            <div class="col-12">
                                <label for="description">Description</label>
                                <div class="field my-3" >
                                    <Textarea
                                        style="width:100%;"
                                        id="description"
                                        v-model.trim="form.description"
                                        required="true" 
                                        rows="5" 
                                        cols="30"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- SWOT -->
            <div class="row">
                <SwotList 
                    :companyId="selectedElement"
                />
            </div>

            <!-- DataTable -->
            <!-- {{ documents  }} -->
            <div :style="{marginTop: '1rem'}" >
                <DataTable
                    :value="documents"
                    :loading="loading"
                    responsiveLayout="scroll"
                    showGridlines
                    :paginator="true"
                    :rows="10"
                    :rowsPerPageOptions="[10,25,50]"
                    :rowHover="true"
                    removableSort
                    dataKey="id"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    v-model:filters="filters"
                    filterDisplay="menu"
                >
                    <template #header>
                        <div>
                            Documents for {{this.company.name}}
                        </div>
                        <div
                            class="table-header row gy-3 d-flex flex-row justify-content-between"
                        >
                            <div class="d-flex flex-row justify-content-md-end">
                                <Button
                                    type="button"
                                    icon="pi pi-filter-slash"
                                    label="Clear"
                                    class="p-button-outlined me-4"
                                    @click="clearFilter()"
                                />
                                <span class="p-input-icon-left" style="position: relative;">
                                    <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                                    <InputText
                                    v-model="filters['global'].value"
                                    placeholder="Keyword Search"
                                    class="keywordSearch"
                                    />
                                </span>
                            </div>
                        </div>
                    </template>
                    <!-- Document Title -->
                    <Column
                        :field="'title'"
                        :header="'Title'"
                        :key="'title'"
                        :sortable="true"
                        style="min-width:40rem"
                    >
                        <template #body="slotProps">
                            <div
                                @click="openEditDocument(slotProps.data)"
                                :style="{'width':'100%', 'height':'100%', 'cursor':'pointer'}"
                            >
                            {{ slotProps.data.title }}
                            </div>
                        </template>
                    </Column>
                    <!-- dateAdded -->
                    <Column
                        :field="'dateAdded'"
                        :header="'Document Added'"
                        :key="'dateAdded'"
                        bodyStyle="text-align: center"
                        :sortable="true"
                    >
                        <template #body="slotProps">
                        {{ formatDate(slotProps.data.dateAdded)  }}
                        </template>
                    </Column>
                    <!-- document Date -->
                    <Column
                        :field="'documentDate'"
                        :header="'Document Date'"
                        :key="'documentDate'"
                        :sortable="true"
                        bodyStyle="text-align: center"
                    >
                        <template #body="slotProps" style="display:flex; justify-content: center; align-items: center;">
                        {{ formatDate(slotProps.data.documentDate) }}
                        </template>
                    </Column>
                    <!-- sourceName -->
                    <Column
                        :field="'sourceName'"
                        :header="'Source Name'"
                        :key="'sourceName'"
                        :sortable="true"
                    >
                        <template #body="slotProps">
                        {{slotProps.data.documentSourceName}}
                        </template>
                    </Column>
                    <!-- Programme -->
                    <Column
                        :header="'Programme'"
                        :key="'program'"
                        :sortable="true"
                    >
                        <template #body="slotProps">
                        {{ getProgramsName(slotProps.data.documentTopics) }}
                        </template>
                    </Column>
                    <!-- PreviewDocumentDialog -->
                    <Column 
                        :exportable="false" 
                        style="min-width: 5rem"
                    >
                        <template #body="slotProps">
                        <div style="display: flex">
                            <div>
                                <div v-if="slotProps.data.text">
                                    <Button
                                        icon="pi pi-eye"
                                        class="p-button-rounded p-button-success mx-2"
                                        @click="openPreviewDocumentDialog(slotProps.data)"
                                        @mouseenter="hoverPreviewDocumentDialogIn(slotProps.data)"
                                        @mouseleave="hoverPreviewDocumentDialogOut"
                                    />
                                </div>
                                <div v-else>
                                    <Button
                                        icon="pi pi-eye"
                                        class="p-button-rounded p-button-success mx-2"
                                        :disabled="true"
                                    />
                                </div>
                            </div>

                            <div>
                                <div v-if="slotProps.data.contract">
                                    <Button
                                        icon="pi pi-file"
                                        class="p-button-rounded p-button-success mx-2"
                                        @click="openPreviewDocumentContracDialig(slotProps.data)"
                                        @mouseenter="hoverPreviewDocumentContractDialogIn(slotProps.data)"
                                        @mouseleave="hoverPreviewDocumentContractDialogOut"
                                    >
                                        <font-awesome-icon icon="fa-solid fa-file-contract" />
                                    </Button>
                                </div>
                                <div v-else>
                                    <Button
                                        icon="pi pi-file"
                                        class="p-button-rounded p-button-success mx-2"
                                        :disabled="true"
                                    >
                                        <font-awesome-icon icon="fa-solid fa-file-contract" />
                                    </Button>
                                </div>
                            </div>

                            <div>
                                <div v-if="slotProps.data.filePublicUrl">
                                <Button
                                    icon="pi pi-file-pdf"
                                    class="p-button-rounded p-button-success mx-2"
                                    @click="openPreviewDocumentFileDialig(slotProps.data)"
                                >
                                </Button>
                                </div>
                                <div v-else>
                                <Button
                                    icon="pi pi-file-pdf"
                                    class="p-button-rounded p-button-success mx-2"
                                    :disabled="true"
                                >
                                </Button>
                                </div>
                            </div>
                        </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <!-- Preview Document -->
            <Dialog
                v-model:visible= "previewDocumentDialog"
                :style="{width: '80%'}"
                :modal="true"
            >
                <template #header>
                <h5> Preview <strong>{{selectedRowDocument.title }} </strong> </h5> 
                </template>
                <div v-html="selectedRowDocument.text" ></div>
            </Dialog>
            <!-- Preview Document Hover -->
            <div 
                class="modalPreview"
                v-show="previewHoverDocumentDialog"
            >
                <div class="modalContainer">
                    <div v-if="selectedRowDocument">
                    <h5>Preview <strong>{{selectedRowDocument.title }} </strong> </h5> 
                    <div v-html="selectedRowDocument.text" class="modalContainer-Text" ></div>
                    </div>
                    <div v-else>
                    loading
                    </div>
                </div>
            </div>
            <!-- Preview DocumentContract -->
            <Dialog
                v-model:visible= "previewDocumentContractDialog"
                :style="{width: '80%'}"
                :modal="true"
            >
                <template #header>
                <h5> Contract for:  {{selectedRowDocumentContract.title  }}</h5>
                </template>
                <div>
                {{selectedRowDocumentContract.contract  }}  
                </div>
            </Dialog>
            <!-- Preview DocumentContract Hover -->
            <div 
                class="modalPreview"
                v-show="previewHoverDocumentContractDialog"
                >
                <div class="modalContainer">
                    <div v-if="selectedRowDocumentContract">
                        <h5>Contract <strong>{{selectedRowDocumentContract.title }} </strong> </h5> 
                        <div>
                        {{selectedRowDocumentContract.contract }}
                        </div>
                    </div>
                    <div v-else>
                        loading
                    </div>
                </div>
            </div>

            <!-- Preview DocumentFile -->
            <Dialog
                v-model:visible= "previewDocumentFileDialog"
                :style="{width: '80%'}"
                :modal="true"
            >
                <template #header>
                <h5> File for {{selectedRowDocumentFile.title  }}</h5>
                </template>
                <div>
                <a
                    :href="selectedRowDocumentFile.filePublicUrl"
                    target="_blank"
                    rel="Document File"
                    class="document-File"
                >
                    <i class="pi pi-file-pdf" style="font-size: 2rem"></i>
                    <label for="file">{{ selectedRowDocumentFile.filePublicUrl.split('/')[selectedRowDocumentFile.filePublicUrl.split('/').length -1] }}</label>  
                </a>
                </div>
            </Dialog>

            <!-- save -->
            <div class="row">
                <div class="col-12 p-4">
                    <div class="d-flex justify-content-end px-3">
                        <Button 
                            label="Save"
                            icon="pi pi-check"
                            class="p-button-success ml-3"
                            :loading="editIsLoading"
                            :disabled="this.v$.form.$invalid || editIsLoading"
                            @click="edit"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
  
<script>
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Textarea from 'primevue/textarea'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from 'vue-toastification';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import formatToGlobalDate from '@/shared/helpers/date.helper';
import Dialog from 'primevue/dialog';
import SwotList from '../components/SwotList.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
const toast = useToast();

export default {
    name: 'EditCompanyPage',
    components: {
        Button,
        InputText,
        Dropdown,
        Textarea,
        ProgressSpinner,
        DataTable,
        Column,
        Dialog,
        SwotList,
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            filters:null, 
            priority:false,
            loading: false,
            loadingCompanyTypes: false,
            loadingCountries: false,
            editIsLoading: false,
            form: {
                name: null,
                companyTypeId: null,
                countryId: 1,
                description: null,
                address: null,
                numberEmployees: null,
            },
            documentTopics: [],
            isCollapse: [],
            // selectedRows  
            selectedRowDocument: null,
            selectedRowDocumentContract: null, 
            selectedRowDocumentFile: null, 

            previewDocumentDialog:false,
            previewHoverDocumentDialog:false,

            previewDocumentContractDialog: false,
            previewHoverDocumentContractDialog:false, 

            previewDocumentFileDialog:false,
        };
    },
    validations() {
        return {
            form: {
                name: { required },
                companyTypeId: { required },
            }
        };
    },
    created(){
        this.initFilters();
    },
    async mounted() {
        await this.getCompany();
        this.assingForm()
        await this.getCompanyTypes();
        await this.getCountries();
        await this.getTopicsByCompanyId();
        await this.getDocumentsByCompanyId();
        this.assignDocumentTopics();
    },
    computed: {
        company(){
            return this.$store.getters.obtainCompany;
        },
        selectedElement(){
            return this.$route.params.companyId;
        },
        topics(){
            return this.$store.getters.obtainTopics;
        },
        companyTypes() {
            return this.$store.getters.obtainCompanyTypes;
        },
        countries() {
            return this.$store.getters.obtainCountries;
        },
        documents(){
            return this.$store.getters.obtainDocuments;
        }
    },
    methods: {
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                ],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        goBack() {
          this.$router.go(-1);
        },
        async getCompany(){
            try{
                this.loading =true; 
                await this.$store.dispatch('obtainCompaniesByCompanyId', this.selectedElement)
                this.loading= false; 
            }
            catch(error){
                toast.error(error.message)
                this.loading= false; 
            }
        },
        async getCompanyTypes() {
            try {
                this.loadingCompanyTypes = true;
                await this.$store.dispatch('obtainCompanyTypes');
                this.loadingCompanyTypes = false;
            } catch (error) {
                toast.error(error.message);
                this.loadingCompanyTypes = false;
            }
        },
        async getCountries() {
            try {
                this.loadingCountries = true;
                await this.$store.dispatch('obtainCountries');
                this.loadingCountries = false;
            } catch (error) {
                toast.error(error.message);
                this.loadingCountries = false;
            }
        },
        async getTopicsByCompanyId(){
            try {
                this.loading = true; 
                await this.$store.dispatch('obtainTopics')
                this.loading= false
                
            } catch (error) {
                toast.error(error.message)
                this.loading = false
            }
        },
        async getDocumentsByCompanyId(){
            try {
                this.loading = true
                // change when we had the documentsByCountryId
                await this.$store.dispatch('obtainDocuments' , {countryId: undefined, companyId: this.selectedElement})
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        assingForm() {
            this.form = Object.assign({}, this.company); 
            (this.form.hasPrioritary) 
            ?this.priority = this.form.hasPrioritary
            : this.priority = false
            delete this.form.hasPriority
        },
        async edit() {
            try {
                if (!this.v$.form.$invalid) {
                    this.editIsLoading = true;
                    this.form.hasPrioritary = this.priority
                    await this.$store.dispatch('modifyCompany', {
                        companyId: this.selectedElement,
                        ...this.form,
                    });
                    // console.log(this.form);
                    // console.log(JSON.stringify(this.form) )
                    this.editIsLoading = false;
                    // toast.success('Successfully updated.');
                    this.goBack()
                } else {
                    toast.error('Wrong company data.');
                }
            } catch (error) {
                this.editIsLoading = false;
                console.error(error);
                toast.error(error);
            }
        },
        assignDocumentTopics() {
            this.documentTopics = []
            if(this.documents){
                this.documents.map(document => document.documentTopics.map(documentTopic =>this.documentTopics.push(documentTopic.topicId)) )
                const uniqueTopics =  this.documentTopics.sort().filter( (item, pos, ary ) =>
                    !pos || item != ary[pos-1]
                )

                // for collapse 
                for(let index = 0; index < uniqueTopics.length; index++ ) {
                    let arrayDocuments = []
                    for(let index = 0; index < this.documents.length; index++){
                        arrayDocuments.push(true)
                    }
                    this.isCollapse.push( arrayDocuments )
                }
                this.documentTopics = uniqueTopics
            }
        },
        // datatable
        formatDate(date) {
            if (!date) return '';
            return formatToGlobalDate(date);
        },
        getProgramsName(programs){
            let programArray=[]
            programs.map(program => {
                if(program.documentProgram){
                programArray.push(program.documentProgram.name) 
                }
            })
            if(programArray.length  >0){
                programArray = programArray.filter((x,i,a)=> a.indexOf(x)=== i)
                return programArray.join(', ') 
            }
            else{
                return '--  '
            }
        },
        // dialog
        openPreviewDocumentDialog(document){
            this.previewDocumentDialog = true;
            this.selectedRowDocument = document;
        },
        hoverPreviewDocumentDialogIn(document){
            this.previewHoverDocumentDialog= true;   
            this.selectedRowDocument = document;
        },
        hoverPreviewDocumentDialogOut(){
            this.previewHoverDocumentDialog= null; 
        },
        // contract
        openPreviewDocumentContracDialig(document){
            this.previewDocumentContractDialog = true;
            this.selectedRowDocumentContract = document
        },
        hoverPreviewDocumentContractDialogIn(document){
            this.previewHoverDocumentContractDialog= true;   
            this.selectedRowDocumentContract = document;
        },
        hoverPreviewDocumentContractDialogOut(){
            this.previewHoverDocumentContractDialog = null
        },
        openEditDocument(event){
            this.$router.push(`/admin/topics/documents/edit/${event.documentId}`);
        },
        // file
        openPreviewDocumentFileDialig(document){
            this.previewDocumentFileDialog = true;
            this.selectedRowDocumentFile = document
        },
    },
    watch: {
        async selectedElement(newVal) {
            if (newVal) {
                await this.getCompany();
                this.assingForm()
                await this.getTopicsByCompanyId();
                await this.getDocumentsByCompanyId();
            }
        },
    },

}

</script>
  
<style scoped >
.keywordSearch{
  /* padding: 0 0 0 2.5rem  !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
.modalPreview{
  position:fixed;
  z-index: 1101;
  top:0; 
  left: 0;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  pointer-events: none;
}
.modalContainer{
  height: 50%;
  width: 80%;
  overflow: hidden;
  background-color: white;
  margin: 0.75rem;
  transform: translate3d(0px, 0px, 0px);
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
}
.modalContainer-Text {
  font-size: 1rem;
}
.document-File{
  display: flex; 
  gap: 0.5rem; 
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.document-File:hover{
  color: red;
}
</style>